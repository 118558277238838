import { KOCImage } from "../shared/KocImage"

const SectionBrandStory = () => {
  return (<section className='py-5'>
    <div className="container">
      <div className="row text-center">
        <div className="col-12 mt-4 ">
          <p className="text-dark-orange h2 fw-700 text-uppercase">Câu chuyện thương hiệu</p>
        </div>
        <div className="col-12 mt-4 ">
          <p className="h3 fw-700">TAMBOOKS - Yoga tự nhiên, sức khoẻ bền vững</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-6">
          <p className="h2 fw-700">Giá trị cốt lõi</p>
          <p className="h6 mt-2">TAMBOOKS không chỉ là một nơi để tập yoga, mà là hành trình quay về bên trong, tìm lại sự cân bằng giữa thân và tâm. Cái tên TAMBOOKS mang một ý nghĩa sâu sắc: "Tam" - chính là tâm, còn "Books" - là cuốn sách. Tâm mình chính là cuốn sách mà chỉ khi chấp nhận những trang giấy đã viết, ta mới có thể viết tiếp một cách sáng suốt. Bạn chính là vũ trụ thu nhỏ. Mọi thứ bạn tìm kiếm đều đã có sẵn bên trong bạn. Khi hiểu được chính mình, ta không còn bị cuốn theo những thứ bên ngoài mà sự vững chãi, bình thản và tự tại sẽ nảy nở trong tâm.</p>
        </div>
        <div className="col-6">
          <div className="position-relative" style={{ paddingTop: '70%' }}>
            <div className="position-absolute top-0 left-0 w-100 h-100">
              <KOCImage className='w-100 h-100 objectfit-cover rounded-10px' alt='something' src='/assets/images/core-value-1.jpg' />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-6">
          <div className="position-relative" style={{ paddingTop: '120%' }}>
            <div className="position-absolute top-0 left-0 w-100 h-100">
              <KOCImage className='w-100 h-100 objectfit-cover rounded-10px' alt='something' src='/assets/images/philosophy.jpg' />
            </div>
          </div>
        </div>
        <div className="col-6">
          <p className="h2 fw-700">Triết lý</p>
          <p className="h6 mt-2">TAMBOOKS tin vào sức mạnh của những gì thuộc về tự nhiên.
            Cơ thể khỏe mạnh khi nó được nuôi dưỡng từ nguồn thực phẩm phù hợp với vùng đất nơi ta sống. Tâm trí an yên khi ta biết lắng nghe chính mình và điều chỉnh nhịp sống theo quy luật tự nhiên.</p>
          <p className="h6 mt-2">Tại TAMBOOKS, yoga không phải là một xu hướng nhất thời, mà là một lối sống bền vững. Chúng tôi hướng đến việc giúp mỗi người cảm nhận được sự thay đổi từ bên trong: nhẹ nhàng hơn, khoẻ mạnh hơn, cân bằng hơn. Khi tập luyện với TAMBOOKS, bạn không cần cố gắng trở thành phiên bản tốt nhất của người khác, mà chỉ cần là chính mình, mạnh mẽ và vững chãi theo cách tự nhiên nhất.</p>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-6">
          <p className="h2 fw-700">Sứ mệnh</p>
          <p className="h6 mt-2">Chúng tôi ra đời để giúp mọi người tìm thấy sự kết nối với cơ thể, để việc tập luyện không còn là áp lực, mà trở thành một phần tự nhiên trong cuộc sống. Với hai hình thức học linh hoạt:</p>
          <ul>
            <li>Yoga Online: Luyện tập mọi lúc, mọi nơi với các lớp từ cơ bản đến chuyên sâu, kết nối trực tiếp cùng giảng viên.</li>
            <li>Yoga tại phòng tập: Trải nghiệm không gian tập luyện gần gũi, nơi mỗi người đều được hướng dẫn tận tâm.</li>
          </ul>
          <p className="h6 mt-2">TAMBOOKS không đặt ra những tiêu chuẩn hình thể hay sự hoàn hảo. Chúng tôi chỉ mong bạn cảm nhận được sự thay đổi của chính mình, từng chút một, theo một cách tự nhiên và trọn vẹn nhất.</p>
          <p className="h6 mt-2">Cam kết:</p>
          <ul>
            <li>Thực hành yoga bền vững: Không chỉ là những động tác, mà là sự thấu hiểu chính mình.</li>
            <li>Tập luyện linh hoạt: Các lớp học phù hợp với từng giai đoạn trong hành trình rèn luyện.</li>
            <li>Hỗ trợ tận tâm: Luôn đồng hành cùng bạn trên con đường chinh phục sức khỏe.</li>
          </ul>
          <p className="h6 mt-2">TAMBOOKS không chỉ là một nơi tập yoga, mà là nơi bạn học cách yêu thương cơ thể, quay về bên trong và xây dựng một sức khỏe bền vững.</p>
        </div>
        <div className="col-6">
          <div className="position-relative" style={{ paddingTop: '100%' }}>
            <div className="position-absolute top-0 left-0 w-100 h-100">
              <KOCImage className='w-100 h-100 objectfit-cover rounded-10px' alt='something' src='/assets/images/mission.jpg' />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-6">
          <div className="position-relative" style={{ paddingTop: '120%' }}>
            <div className="position-absolute top-0 left-0 w-100 h-100">
              <KOCImage className='w-100 h-100 objectfit-cover rounded-10px' alt='something' src='/assets/images/core-value-2.png' />
            </div>
          </div>
        </div>
        <div className="col-6">
          <p className="h2 fw-700">Giá trị cốt lõi</p>
          <ul>
            <li>Hòa hợp với sự vận hành của vũ trụ - Mọi thứ trong tự nhiên đều có nhịp điệu riêng. Khi con người sống hài hòa với chính mình, với cơ thể và với dòng chảy của sự sống, sức khỏe và sự bình an tự nhiên nảy nở.</li>
            <li>Chủ động tạo dựng cuộc sống - Hạnh phúc không phải là điều ta chờ đợi, mà là điều ta tạo ra. TAMBOOKS hướng dẫn mỗi người tự mình xây dựng nền tảng sức khỏe thể chất và tinh thần, để không còn phụ thuộc vào ngoại cảnh.</li>
            <li>Sống tỉnh thức - Yoga không chỉ là thực hành trên thảm, mà là nghệ thuật sống. Mọi hành động, lời nói, suy nghĩ đều là một phần của sự tu dưỡng. Khi ta biết quan sát bản thân, ta có thể thay đổi chính mình và từ đó thay đổi thế giới xung quanh.</li>
            <li>Tận tâm trong từng hành động - Dù là một bài tập, một hơi thở hay một lời hướng dẫn, tất cả đều cần xuất phát từ sự chân thành. Chúng tôi cam kết mang đến chất lượng đào tạo không chỉ bằng kỹ thuật, mà còn bằng sự tận tâm và thấu hiểu.</li>
            <li>Tự do nội tại - Không có một hình mẫu nào cho sự hoàn hảo. Khi ta thực sự hiểu bản thân, ta thoát khỏi những kỳ vọng của xã hội, những áp lực của cái tôi, để sống một cách tự do và trọn vẹn nhất.</li>
          </ul>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-6">
          <p className="h2 fw-700">Trách nhiệm cộng đồng</p>
          <p className="h6 mt-2">Cuộc sống không chỉ là về chính ta, mà còn là về những gì ta cống hiến cho thế giới này. TAMBOOKS không đơn thuần chỉ dạy yoga, mà chúng tôi khơi dậy một ý thức mới về sự kết nối giữa con người với chính mình, với cộng đồng và với thiên nhiên.</p>
          <ul>
            <li>Hướng con người đến sức khỏe toàn diện - Yoga không chỉ là một tập hợp các tư thế, mà là nghệ thuật của sự hài hòa. Khi ta chăm sóc chính mình một cách đúng đắn, ta không chỉ khỏe mạnh hơn mà còn mang lại năng lượng tích cực cho những người xung quanh. TAMBOOKS mong muốn giúp con người không chỉ rèn luyện cơ thể mà còn mở rộng tâm trí, sống một cuộc đời ý nghĩa hơn.</li>
            <li>Lan tỏa lối sống tỉnh thức - Mọi thứ xung quanh ta đều có sự kết nối. Nếu ta muốn một thế giới bình yên, ta phải bắt đầu từ chính tâm trí mình. TAMBOOKS lan tỏa tinh thần tỉnh thức trong từng lớp học, giúp học viên không chỉ tập luyện trên thảm mà còn thực hành chánh niệm trong cuộc sống hàng ngày.</li>
            <li>Bảo vệ và trân quý thiên nhiên - Thiên nhiên không phải là thứ bên ngoài ta, mà là một phần của ta. Chúng tôi cam kết thực hành và hướng dẫn yoga theo hướng bền vững, khuyến khích sự tôn trọng thiên nhiên, giảm thiểu tác động tiêu cực đến môi trường. Khi ta sống hài hòa với thiên nhiên, ta cũng sẽ tìm thấy sự hài hòa trong chính mình.</li>
            <li>Xây dựng một cộng đồng tràn đầy năng lượng tích cực - Khi những con người có cùng chí hướng hội tụ, một dòng chảy năng lượng mạnh mẽ được tạo ra. TAMBOOKS không chỉ là một không gian tập luyện, mà còn là một cộng đồng nơi mỗi người có thể tìm thấy sự đồng hành, sẻ chia và nâng đỡ lẫn nhau trên hành trình phát triển thân - tâm.</li>
          </ul>
        </div>
        <div className="col-6">
          <div className="position-relative" style={{ paddingTop: '140%' }}>
            <div className="position-absolute top-0 left-0 w-100 h-100">
              <KOCImage className='w-100 h-100 objectfit-cover rounded-10px' alt='something' src='/assets/images/responsib.jpg' />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>)
}

export default SectionBrandStory